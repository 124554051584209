// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-bounties-claims-verdict-tsx": () => import("./../../../src/pages/bounties/claims/verdict.tsx" /* webpackChunkName: "component---src-pages-bounties-claims-verdict-tsx" */),
  "component---src-pages-bounties-index-tsx": () => import("./../../../src/pages/bounties/index.tsx" /* webpackChunkName: "component---src-pages-bounties-index-tsx" */),
  "component---src-pages-bounties-view-index-tsx": () => import("./../../../src/pages/bounties/view/index.tsx" /* webpackChunkName: "component---src-pages-bounties-view-index-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard/[...].tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-landing-email-verification-success-tsx": () => import("./../../../src/pages/landing/email/verification-success.tsx" /* webpackChunkName: "component---src-pages-landing-email-verification-success-tsx" */),
  "component---src-pages-landing-stripe-connect-activate-tsx": () => import("./../../../src/pages/landing/stripe-connect/activate.tsx" /* webpackChunkName: "component---src-pages-landing-stripe-connect-activate-tsx" */),
  "component---src-pages-landing-stripe-connect-activated-tsx": () => import("./../../../src/pages/landing/stripe-connect/activated.tsx" /* webpackChunkName: "component---src-pages-landing-stripe-connect-activated-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-new-tsx": () => import("./../../../src/pages/new.tsx" /* webpackChunkName: "component---src-pages-new-tsx" */)
}

